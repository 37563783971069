import React, { useState } from 'react'
import Landing_modal from '../landing_modal'
import styles from './getTurfTownModal.module.scss'
import Image from 'next/image'
import classNames from 'classnames'
import Link from 'next/link'

interface GetTurfTownModalPropType {
    show: boolean;
    onClose: () => void;
}

const GetTurfTownModal = ({ show, onClose }: GetTurfTownModalPropType) => {

    return (
        <Landing_modal show={show} onClose={onClose}>
            <div className={styles['download_modal']} >
                <div className={styles['left']}>
                    <p className={styles['modal_title']}>
                        A game is around the corner, scan this QR code.
                    </p>

                    <ul className={classNames(styles.modal_list)}>
                        <li className='mb-1.5 lg:mb-3 list-disc listdisc' style={{ color: "#B4B4B8", fontFamily: "Nexa-TextBook" }}>Open your phone camera and point it at the QR code.</li>
                        <li className='mb-1.5 lg:mb-3 list-disc listdisc' style={{ color: "#B4B4B8", fontFamily: "Nexa-TextBook" }}>Alternatively, download any QR code scanner to scan.</li>
                        <li className='mb-1 list-disc listdisc' style={{ color: "#B4B4B8", fontFamily: "Nexa-TextBook" }}>Click on the link generated to download Turf Town.</li>
                    </ul>
                    <div >
                        <div className={classNames(styles['row'], styles['align-center'])}>
                            <a href='https://apps.apple.com/in/app/turf-town/id1490231308' target="__blank" aria-label='Read more about Seminole tax hike'>  <Image src={'/landingimages/appleplaystores.svg'} alt="apple" width={18} height={23} loading='lazy' /></a>
                            <a href='https://play.google.com/store/apps/details?id=com.turftown' target="__blank" aria-label='Read more about Seminole tax hike'> <Image src={'/landingimages/playstoreicons.png'} height={23} width={20} className={styles['ml-16']} alt="lay" loading='lazy' /></a></div>
                    </div>
                </div>
                <div className={styles['right']}>
                    <Image height={40} width={40} src={'/landingimages/popupclose1.svg'} className={styles.closebutton} alt="close"
                        onClick={onClose} />
                    <Image height={180} width={180} src={'/landingimages/Turfqr.png'} className={styles.scanimages} alt="qr" loading='lazy' />
                </div>
            </div>
        </Landing_modal>
    )
}

export default GetTurfTownModal